(function($) {
  $(document).ready(function() {
    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on("click", function(
      e
    ) {
      var $href = $(this).attr("href"),
        $tgt = $($href == "#" || $href == "" ? "html" : $href),
        $pos = $tgt.offset().top,
        $correctH = 65;

      if (window.innerWidth > 1000) {
        if ($(".navigation__fix").hasClass("fixed")) {
          $correctH = 90;
        } else {
          $correctH = 200;
        }
      }

      $("html, body").animate(
        {
          scrollTop: $pos - $correctH
        },
        500
      );
      e.preventDefault();
    });

    /*var mql = window.matchMedia("(min-width : 1000px)");
    mql.addListener(menuClose);
    menuClose(mql);

    function menuClose(mql) {
      if (mql.matches) {
        $("#menu").removeClass('menu_open');
        $('.menu-trigger').removeClass('triggeractive');
      }
    }*/

    $(".slider").slick({
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 500,
      pauseOnHover: false
    });

    $(".top-gallery__list").slick({
      arrows: false,
      slidesToShow: 5,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      speed: 5000,
      centerMode: true,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            //601px～1024pxでは3画像表示
            slidesToShow: 2
          }
        }
      ]
    });

    // gmap iframe
    $(".gmap").click(function() {
      $(".gmap iframe").css("pointer-events", "auto");
    });
  });
})(jQuery);

window.addEventListener("DOMContentLoaded", function() {
  //set noopener

  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links, function(link) {
    if (link.hasAttribute("rel") === false) {
      link.setAttribute("rel", "noopener");
    }
  });

  //hmb

  function gnavi() {
    const toggle = document.getElementById("toggle");

    if (!toggle) {
      return;
    }

    const menu = document.getElementById("menu");
    const body = document.querySelector("body");

    toggle.addEventListener("click", function(e) {
      menu.style.transition = "all 0.5s ease-in-out";
      e.preventDefault();
      body.classList.toggle("menu-open");
    });

    window.addEventListener("resize", function() {
      if (window.innerWidth >= 768) {
        menu.style.transition = "none";
        body.classList.remove("menu-open");
      }
    });

    menu.addEventListener("click", function(e) {
      if (window.innerWidth < 768) {
        e.preventDefault();
        body.classList.remove("menu-open");
      }
    });

    var snsList = document.getElementById("js-sns");
    var snsHtml = snsList.innerHTML;

    var menuWrap = document.querySelector("#menu .menu__wrap");
    var li = document.createElement("li");
    li.classList.add("sns");
    li.innerHTML = snsHtml;
    menuWrap.appendChild(li);

    menuWrap.addEventListener("click", function(e) {
      e.stopPropagation();
    });
  }

  gnavi();

  objectFitImages(".ofi");

  function linkInSite() {
    const hash = location.hash;
    if (hash !== "") {
      window.scrollBy(0, -60);
    }
  }
  window.addEventListener("load", linkInSite);
});

window.addEventListener("load", function() {
  const loading = document.querySelector("#loading");
  loading.classList.add("finished");
});

//fixed

function headerFix() {
  const navigationFix = document.querySelector(".navigation__fix");
  let scr;
  let tgtPosition;

  function addFixed() {
    scr = window.pageYOffset;
    tgtPosition = window.innerHeight / 4;
    if (window.innerWidth >= 768) {
      if (scr > tgtPosition) {
        navigationFix.classList.add("fixed");
      } else {
        navigationFix.classList.remove("fixed");
      }
    }
  }
  addFixed();

  window.addEventListener("scroll", addFixed);
  window.addEventListener("load", addFixed);
}

headerFix();

function parallax() {
  const parallax = document.querySelectorAll(".parallax");

  const options = {
    root: null,
    rootMargin: "0px  0px -150px",
    transhold: 0
  };

  const observer = new IntersectionObserver(doWhenIntersect, options);

  [...parallax].forEach(elm => {
    observer.observe(elm);
  });

  function doWhenIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("parallaxActive");
      } else {
        entry.target.classList.remove("parallaxActive");
      }
    });
  }
}

parallax();

class RemoveClass {
  constructor() {
    this.removeClass = window.addEventListener(
      "DOMContentLoaded",
      this._removeClass
    );
  }
  _removeClass() {
    const currentLink = document.querySelector(".menu__wrap .current");
    const blogLink = document.querySelector(".menu__wrap .blog");

    if (currentLink !== null) {
      if (currentLink.classList.contains("home")) {
        blogLink.classList.remove("blog");
      }
    }
  }
}
new RemoveClass();

class MenuCorrect {
  constructor(items) {
    this.items = items;
    this.removeSpl = window.addEventListener(
      "DOMContentLoaded",
      this._removeSpl.bind(this)
    );
    this._toLocaleString = window.addEventListener(
      "DOMContentLoaded",
      this._toLocaleString.bind(this)
    );
  }

  _removeSpl() {
    [...this.items].forEach(item => {
      const spl = item.querySelector(".menu__list__item__spl");
      const splText = spl.innerHTML.trim();
      if (splText === "") {
        spl.parentNode.removeChild(spl);
      }
    });
  }
  _toLocaleString() {
    [...this.items].forEach(item => {
      const price = item.querySelector(".menu__list__item__price__number");
      price.innerHTML = Number(price.innerHTML).toLocaleString();
    });
  }
}

/*const menuListItemTgts = document.querySelectorAll(".menu__list__item");
if (menuListItemTgts.length > 0) {
  const menuListItem = new MenuCorrect(menuListItemTgts);
}*/

class removeThumb {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this.remove = window.addEventListener(
      "DOMContentLoaded",
      this._remove.bind(this)
    );
  }
  _remove() {
    if (this.els.length > 0) {
      [...this.els].forEach(el => {
        const thumb = el.querySelector(".posts__list__item__img");
        if (thumb.innerHTML.includes("noimage")) {
          thumb.parentNode.removeChild(thumb);
        }
      });
    }
  }
}

new removeThumb(".unnecessary-noimage");
